// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appUrl: 'https://activity-feed-ui.qa.zonarsystems.net/',
  authEnv: 'qa',
  name: 'qa',
  apiBase: 'https://api-qa.zonarsystems.net',
  reverseGeocoderApiBase: 'https://zonar-nonprod-qa.apigee.net',
  apiApigeePrefix: '/activity',
  devTools: true,
  zonarApps: {
    gtcxMapsBaseUrl: 'https://maps.qa.zonarsystems.net',
    alertManagementBaseUrl: 'https://alertmanagement.qa.zonarsystems.net'
  },
  eventSubscriptionApiBase: 'https://event-subscription-api.qa.zonarsystems.net/v1',
  alertManagementAdminConfigs: {
    roleId: 'e3fd404b-fb1c-47ce-bc3c-60c2de404ee4',
    applicationId: 'e306148d-bcb5-4d3d-9b58-5abbd41f8f16'
  },
  //  TODO: Setup your application's authentication variables: see
  //  https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth
  // for instructions on how to do that.
  auth: {
    clientID: 'iu5TFEdrFIkoBTIBpAezSHqjAuJBn4cA',
    domain: 'qa-login.zonarsystems.net',
    audience: 'http://apiqa.zonarsystems.net/',
    // These come from the Core APIs as your application is built.
    applicationId: 'af9d2e76-981b-41bc-8488-aecc9f1ea592',
    defaultZonarRole: 'a0ae2891-38d5-4832-99bb-b1c6d883aa84',
    alertManagementAppId: 'e306148d-bcb5-4d3d-9b58-5abbd41f8f16',
    alertManagementRoleId: '70b3a9ad-2f15-4cc5-967e-9f365695d038',
    useRefreshTokens: true
  },
  userPreferenceApiBase: {
    url: 'https://api-qa.zonarsystems.net/alert/v1'
  },
  datadog: {
    applicationId: 'd9510c6e-b793-4156-abbd-c755919f1866',
    clientToken: 'pub7c67ecc6ecb6ec6a494008ed10faf740',
    siteUrl: 'us5.datadoghq.com'
  },
  devCycle: {
    clientId: 'dvc_client_3a03d1aa_1354_432c_af11_23757d5b2e7a_f7b1a96'
  },
  googleTagManager: {
    id: 'GTM-PM9R42P',
    auth: '2KLWSiRQSUBTl0bsYimfIQ',
    environment: 'env-4'
  },
  googleOptimize: {
    gOptId: null,
    powerOnAbTestId: null
  },
  production: false,
  region: 'NA',
  coreEntityApiBase: {
    url: 'https://api-qa.zonarsystems.net/core/v1'
  },
  i18n: {
    supportedLanguages: ['en-US'],
    defaultLanguage: 'en-US'
  },
  featureFlags: {
    refreshActivityBtn: true
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
